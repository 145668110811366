<script setup>
const drawer = ref(true);
const items = ref([
  { type: 'divider' },
  { type: 'subheader', title: 'Үндсэн' },
  {
    type: "item",
    title: "Эхлэл",
    to: "/",
    icon: "mdi-home",
  },
  { type: 'divider' },
  { type: 'subheader', title: 'Хяналт' },
  {
    type: "item",
    title: "Мэдээнүүд",
    to: "/items",
    icon: "mdi-newspaper-variant-outline",
  },
  {
    type: "item",
    title: "Даалгавар",
    to: "/tasks",
    icon: "mdi-abacus",
  },
  {
    type: "item",
    title: "Алдаанууд",
    to: "/crawlerrors",
    icon: "mdi-truck-alert",
  },
  { type: 'divider' },
  { type: 'subheader', title: 'Удирдлага' },
  {
    type: "item",
    title: "Бүртгэл",
    to: "/credentials",
    icon: "mdi-credit-card",
  },
  {
    type: "item",
    title: "Түлхүүр үгс",
    to: "/topics",
    icon: "mdi-approximately-equal",
  },
  { type: 'divider' },
  { type: 'subheader', title: 'Эх сурвалжууд' },
  {
    type: "item",
    title: "Мэдээний сайт",
    to: "/sources/news",
    icon: "mdi-earth",
    exact: true,
  },
  {
    type: "item",
    title: "Фэйсбүүк",
    to: "/sources/facebook",
    icon: "mdi-facebook",
    exact: true,
  },
  {
    type: "item",
    title: "Твиттер",
    to: "/sources/twitter",
    icon: "mdi-twitter",
    exact: true,
  },
  {
    type: "item",
    title: "Хянах",
    to: "/sources-review",
    icon: "mdi-check-decagram",
    exact: true,
  },
  { type: 'divider' },
  { type: 'subheader', title: 'Админ' },
  {
    type: "item",
    title: "Хэрэглэгчид",
    to: "/customers",
    icon: "mdi-account",
  },
]);
</script>
<template>
  <v-navigation-drawer location="left" permanent>
    <v-list nav density="compact">
      <v-list-subheader class="my-4 text-h5 font-weight-medium text-primary" style="line-height: 30px">
        <v-icon icon="mdi-shield-star" class="mr-2"></v-icon>
        ISMAN
      </v-list-subheader>

      <template v-for="item in items" :key="item.to">
        <v-divider v-if="item.type == 'divider'" class="my-4" />
        <v-list-subheader v-if="item.type == 'subheader'" class="font-weight-bold text-uppercase mb-2">{{ item.title
        }}</v-list-subheader>
        <v-list-item :value="item" color="primary" :to="item.to" :exact="item.exact" v-if="item.type == 'item'">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
