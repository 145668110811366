<template>
  <v-layout>
    <v-main>
      <v-container>
        <slot></slot>
        <NavigationLeft />
      </v-container>
    </v-main>
  </v-layout>
</template>

<style>
html,
body {
  background: #F5F8FA;
  min-height: 100%;
}
</style>